<template>
  <div class="lg:flex flex-col min-h-screen font-albert overflow-x-hidden" :style="style">
    <img src="@/assets/images/careerlogo.svg" alt="logo" class="object-cover block mx-auto" :style="imgStyle" />
    <div class="md:px-8 py-10 mx-auto grow">
      <router-view />
    </div>
  </div>
</template>

<script setup>
const backgroundImage = require('@/assets/images/auth-bg.png');
const style = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
};

const imgStyle = {
  paddingTop: '2rem',
};
</script>
