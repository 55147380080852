import AuthLayout from '../layouts/AuthLayout.vue';
import DashboardLayout from '../layouts/DashboardLayout.vue';
import { UserMiddleware } from '@/utils/middleware';

const routes = [
  {
    path: '/authLayout',
    name: 'AuthLayout',
    component: AuthLayout,
    children: [
      {
        path: '/',
        redirect: { name: 'Dashboard' },
        meta: {
          middleware: [UserMiddleware],
        },
      },
      {
        path: '/register',
        name: 'Register',
        meta: {
          middleware: [UserMiddleware],
        },
        component: () => import(/* webpackChunkName: "Register" */ '@/views/auth/Register.vue'),
      },
      {
        path: '/login',
        name: 'Login',
        meta: {
          middleware: [UserMiddleware],
        },
        component: () => import(/* webpackChunkName: "Login" */ '@/views/auth/Login.vue'),
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/auth/ResetPassword.vue'),
      },
      {
        path: '/verify-password',
        name: 'VerifyPassword',
        component: () => import(/* webpackChunkName: "VerifyPassword" */ '@/views/auth/VerifyPassword.vue'),
      },
      {
        path: '/register-verify',
        name: 'VerifyAccount',
        meta: {
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "VerifyPassword" */ '@/views/auth/RegisterVerify.vue'),
      },
      {
        path: '/new-password',
        name: 'NewPassword',
        component: () => import(/* webpackChunkName: "NewPassword" */ '@/views/auth/NewPassword.vue'),
      },
    ],
  },
  {
    path: '/onboard',
    name: 'Onboard',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "Onboard" */ '@/views/auth/Onboard.vue'),
  },
  {
    path: '/file/:uri',
    name: 'TalentResume',
    component: () => import(/* webpackChunkName: "TalentResume" */ '@/views/TalentResume.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboardLayout',
    name: 'DashboardLayout',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Jobs" */ '@/views/Dashboard.vue'),
        meta: {
          title: 'Dashboard',
          text: '',
          requiresAuth: true,
        },
      },
      {
        path: '/jobs',
        name: 'Jobs',
        component: () => import(/* webpackChunkName: "Jobs" */ '@/views/Jobs.vue'),
        meta: {
          title: 'Available Jobs',
          text: '',
          requiresAuth: true,
        },
      },
      {
        path: '/:company/:id/:title',
        name: 'Jobs-Description',
        component: () => import(/* webpackChunkName: "SingleJob" */ '@/views/JobDescription.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/referrals',
        name: 'Referrals',
        component: () => import(/* webpackChunkName: "Jobs" */ '@/views/Referrals.vue'),
        meta: {
          title: 'Referrals',
          text: '',
          requiresAuth: true,
        },
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import(/* webpackChunkName: "Jobs" */ '@/views/Wallet.vue'),
        meta: {
          title: 'Wallet',
          text: '',
          requiresAuth: true,
        },
      },
      {
        path: '/applied',
        name: 'Applied',
        component: () => import(/* webpackChunkName: "Applied" */ '@/views/Applied.vue'),
        meta: {
          title: 'Applications',
          text: 'Here is all the jobs you have applied for',
          requiresAuth: true,
        },
      },
      {
        path: '/applied/:id',
        name: 'Applications-Description',
        component: () => import(/* webpackChunkName: "SingleApplication" */ '@/views/ApplicationDescription.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/conversations',
        name: 'Conversations',
        component: () => import(/* webpackChunkName: "Applied" */ '@/views/Conversation.vue'),
        meta: {
          title: 'Conversations',
          text: 'Here is all the jobs you have applied for',
          requiresAuth: true,
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Assessment" */ '@/views/Profile.vue'),
        meta: {
          title: 'Edit your Profile',
          text: '',
          requiresAuth: true,
        },
      },
      {
        path: '/password',
        name: 'Password',
        component: () => import(/* webpackChunkName: "Assessment" */ '@/views/auth/PasswordSecurity.vue'),
        meta: {
          title: 'Password & Security',
          text: '',
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
