
<template>
  <ul class="flex">
    <li class="" v-for="nav in navItems" :key="nav.name">
      <router-link :to="nav.url" class="p-3 mr-3 text-[#ADADAD]">{{ nav.name }}</router-link>
    </li>
  </ul>
</template>

<script setup>
import { ref } from 'vue';

const navItems = ref([
  {
    name: 'Dashboard',
    url: '/dashboard',
  },
  {
    name: 'Jobs',
    url: '/jobs',
  },
  {
    name: 'Referrals',
    url: '/referrals',
  },
  {
    name: 'Applied Jobs',
    url: '/applied',
  },

  // {
  //   name: 'Wallet',
  //   url: '/wallet',
  // },
]);
</script>